import { ContentContainer, Headline, Subheadline, Wrapper } from "./HomepageHeader.styles"
import React, { useContext } from 'react';

import { AnalyticsContext } from "../../../../context"
import Button from '../../../global/Button'

const HomepageHeader = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)
  return(
  <Wrapper>
    <ContentContainer className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <Headline>Keep your team well-fed and happy</Headline>
          <Subheadline>Grubhub Corporate offers you the most ways to feed your employees, wherever they are, with no sign-up or corporate transaction fees.</Subheadline>
          <Button 
            to={props.linkToSection || "/get-started/" } 
            onClick={() => handleTealiumEvent(
              {
                eventcategory: "homepage_interaction",
                eventaction: "get started_cta",
                eventlabel: "hero banner",
              }
            )}
          >Get started</Button>
            <Button
                to={"https://corporate.grubhub.com/contact-us/"}
                type={"secondary"}
                onClick={() => handleTealiumEvent(
                    {
                        eventcategory: "homepage_interaction",
                        eventaction: "get a demo_cta",
                        eventlabel: "hero banner",
                    }
                )}
            >Get a demo</Button>
        </div>
      </div>
    </ContentContainer>
  </Wrapper>
)};

export default HomepageHeader;
