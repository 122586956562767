import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 500px;
    background-color: #e7e4f8;
    background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628112767/Grubhub%20Corporate%20Website/2021/Header%20Images/Main%20Header/MainHeader-Desktop-Final.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    display: flex;
    align-items: center;
    @media (${queries.max_break_md}){
        text-align:center;
        padding-top:40px;
        align-items: flex-start;
        padding-bottom: 500px;
        background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628103692/Grubhub%20Corporate%20Website/2021/Header%20Images/Main%20Header/CorpSiteHeaderOptions-TAB-1.jpg");
        background-position: bottom center;
    }
    @media (${queries.max_break_sm}){
        padding-top:20px;
        padding-bottom: 300px;
        background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628113241/Grubhub%20Corporate%20Website/2021/Header%20Images/Main%20Header/CorpSiteHeaderOptions-MOB-FINAL.jpg");
    }
`;

export const ContentContainer = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    
    a[type="secondary"]{
        margin-left: 15px;
    }
    
    @media (${queries.max_break_md}){
        text-align:center;
        padding-top:30px;
        a[type="secondary"]{
            margin-left: 0px;
            margin-top: 15px;
        }
    }
    @media (${queries.max_break_sm}){
        a[type="secondary"]{
            margin-left: 15px;
        }
    }
    @media (max-width: 349px){
        div.row > div{
            display: flex;
            flex-direction: column;
        }
        a{
            margin-left: auto;
            margin-right: auto;
        }
        a[type="secondary"]{
            margin-left: auto;
            margin-top: 15px;
        }
    }
`;

export const Headline = styled.h1`
    font-weight: bold;
    font-size: clamp(var(--fontSize-6), 4.5vw, var(--fontSize-8));
`;

export const Subheadline = styled.p`
    margin-bottom: 30px;
    font-size: var(--fontSize-2);
`;