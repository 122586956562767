import Button from "../components/global/Button"
import HomepageHeader from "../components/page-specific/Homepage/HomepageHeader"
import HomepageTabbedCardsSection from '../components/page-specific/Homepage/HomepageTabbedCardsSection'
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import MarketoForm from "../components/global/MarketoForm"
import { queries } from "../breakpoints"
import styled from "styled-components"
import SectionWrapper from "../components/sections/SectionWrapper"

const IndexPage = ({ location }) => {

  const showThankYouScreen = () => {
    // Show the thank you message
    let thankYou = document.getElementById('marketo-thank-you')
    thankYou.style.display = 'block';

    // Hide the form
    let form = document.getElementById('marketo-form-index')
    form.style.display = "none"
  }

  return (
    <Layout
      location={location}
      title={"Grubhub Corporate Accounts | Corporate Food Ordering"}
      description={"A Grubhub Corporate Account helps thousands of companies feed their teams by simplifying the process of ordering meals. Make your hungry team happy today!"}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "homepage",
        pageName: "homepage",
        brand: "grubhub"
      }}
      >
      <HomepageHeader linkToSection="/get-started/"/>
      <ValuePropsSection
        headline="Explore the industry's most flexible meal perks platform"
        layout="vertical"
        maxWidth={900}
        valueProps={[
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp1.png",
            headline:"Feed any occasion",
            subheadline:"From virtual meetings to in-office events, boost employee engagement and delight clients with fast and reliable food delivery."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp2.png",
            headline:"Cater to every taste with group ordering",
            subheadline:"Grubhub has the most group ordering options compared to any other platform, which means your employees can order the food they want and everyone’s meal shows up at the same time."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp3.png",
            headline:"Offer benefits that go beyond business hours",
            subheadline:"With Grubhub+, your employees can enjoy unlimited $0 delivery fees on eligible orders during or after work.***"
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp4.png",
            headline:"Satisfy every craving with the most local restaurants",
            subheadline:"Grubhub has the most local picks compared to any other platform, which means you’re supporting restaurants in your community with each order."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp5.png",
            headline:"Discover tax-exempt ordering options",
            subheadline:"Grubhub is the only platform with built-in tax-exempt ordering. For admins or team members at eligible organizations,* ordering is made easy with automatic tax removal at checkout on all qualifying orders."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp6.png",
            headline:"Control costs with budgets and rules",
            subheadline:"Save up to 30% on food costs annually while reducing waste.** Your employees order what they’ll actually eat, and you only pay for what’s ordered."
          }
        ]}
      />
      <ButtonWrapper>
        <Button to={ "/get-started/?utm_source=homepage&utm_medium=button&utm_campaign=additional_button" }>Get started</Button>
      </ButtonWrapper>
      <h2 style={{
          fontWeight: 'bold',
          textAlign: 'center',
          paddingTop: '50px',
          paddingLeft: '15px',
          paddingRight: '15px',
          backgroundColor: '#F9F9F9',
          marginBottom: '0',
      }}>
          The most ways to order
      </h2>
      <StyledParagraph>Easy ordering. Delicious food. Reliable delivery to wherever your team sits, with dedicated support every step of the way. Plus, clients can elevate the corporate food ordering experience with white-glove service, including on-site staffing.</StyledParagraph>
      <HomepageTabbedCardsSection/>
      <QuoteSection
        quote='“Grubhub has been instrumental in keeping our employees happy.”'
        author="Meghan M"
        position="Office Administrator at Compass"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347427/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/PARTNERLOGOS-Compass.png"
        alt="Compass logo"
        ctaLink="/"
        ctaText="See more"
      />
      <ValuePropsSection
        headline="Delivering happiness is our #1 priority"
        layout="horizontal"
        backgroundColor="#F9F9F9"
        valueProps={[
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Easiest for employees",
            subheadline:"Ordering is simple and easy. There’s no learning curve, which means your team will enjoy ordering good food from day one. Plus, Grubhub has the most ways to order, so your team can order whenever and however they want."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Best for office managers",
            subheadline:"The most group ordering options. Controlled, reduced costs. Guaranteed on-time delivery at the lowest price or we make it right. All different ways to say: get added peace of mind with Grubhub Corporate."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Built for businesses",
            subheadline:"Since 1999, Grubhub Corporate has been proudly serving corporate clients across America. The first to feed a hybrid workforce, we bring you cost-effective and flexible solutions that fit your team’s evolving needs."
          }
        ]}
      />
      <StyledSectionWrapper id="marketo-form-index">
          <div className="row">
              <StyledHeader className="col-lg-6 col-12 text-center text-lg-left">
                  <Headline>Discover the industry leader in flexible meal benefits</Headline>
                  <Subheadline>Fill out the form to learn more about corporate accounts.</Subheadline>
                  <Image src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673474/Grubhub%20Corporate%20Website/2021/Photography%20Elements/5-ContactUs/GeneralContactUs-Image.jpg" alt="Contact us" />
              </StyledHeader>
              <StyledFormContainer className="col-lg-6 col-12">
                <ContainerMarketo>
                  <HeadlineForm>Sign up to learn more</HeadlineForm>
                  <MarketoForm labelBellow={false} id={1785} onSubmit={() => showThankYouScreen()}/>
                  <Legal>By clicking "Submit" or by using this site, you agreee to Grubhub's <a href="https://www.grubhub.com/legal/terms-of-use">terms of use</a> and <a href="https://www.grubhub.com/legal/privacy-policy">privacy policy</a>.</Legal>
                </ContainerMarketo>
              </StyledFormContainer>
          </div>
      </StyledSectionWrapper>

      {/* Thank you page (hidden until successful submit) */}
      <SectionWrapper id="marketo-thank-you" style={{ display: 'none' }}>
          <ThankYouContainer className="row">
            <div className="col-lg-6 col-12" style={{padding:0}}>
              <ThankYouImage />
            </div>
            <ThankYouMessageContainer className="col-lg-6 col-12">
              <img
                width={125}
                alt=""
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673958/Grubhub%20Corporate%20Website/2021/Navigation/SuccessState-Checkmark.svg"/>
              <h2>Thanks for reaching out, we'll be in touch shortly!</h2>
              <p>In the meantime, check out our blog to learn more about supporting your hybrid workforce.</p>
              <Button to="/blog/" typee="primary">See blog</Button>
            </ThankYouMessageContainer>
          </ThankYouContainer>
        </SectionWrapper>
    </Layout>
  )
}

export default IndexPage;


const StyledSectionWrapper = styled(SectionWrapper)`
  @media(${queries.max_break_md}){
    padding:0;
    padding-bottom: 50px;
    .container{
      max-width: unset;
    }
  }
`;

const StyledHeader = styled.div`
  @media(${queries.max_break_md}){
    display: grid;
    grid-template-areas: "image" "headline" "subheadline";
    align-content: center;
    padding: 0;
  }
`;

const Headline = styled.h2`
  font-weight: bold;
  font-size: clamp(var(--fontSize-5), -0.875rem + 8.333vw, var(--fontSize-7));
  @media(${queries.max_break_md}){
    grid-area: headline;
    padding: 0 15px;
  }
  
`;

const HeadlineForm = styled.h3`
  align-self: flex-start;
  font-weight: bold;
  font-size: var(--fontSize-4);
  margin-bottom: 20px;
  @media(${queries.max_break_md}){
    text-align: center;
    width: 100%;
  }
`;

const Subheadline = styled.p`
  font-size: var(--fontSize-2);
  @media(${queries.max_break_md}){
    grid-area: subheadline;
    padding: 0 15px;
  }
`;

const Image = styled.img`
  width: 90%;
  border-radius: 4px;
  margin-bottom: 50px;
  @media(${queries.max_break_md}){
    grid-area: image;
    width:100%;
    border-radius: 0;
    margin-bottom: 20px;
  }

`;

const StyledParagraph = styled.p`
  font-size: 19px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
  margin-bottom: 0;
  background-color: #F9F9F9;
  padding-left: 19%;
  padding-right: 19%;
  @media(${queries.max_break_md}){
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledFormContainer = styled.div`
  @media(${queries.max_break_md}){
    padding:20px 40px 0; 
  }
  @media(${queries.max_break_sm}){
    padding:20px 20px 0; 
  }
`;

const ContainerMarketo = styled.div`
    background-color: #f6f6f6;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    font-family: var(--font-body);
    box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    @media(${queries.min_break_md}){
        padding: 55px;
    }

    .mktoFormCol {
        margin-bottom: 25px;
        @media(${queries.min_break_md}){
            margin-bottom: 35px;
            width: 50% !important;

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .mktoLabel {
        display: flex;
        font-size: var(--fontSize-0);
        font-weight: 300;
        margin-bottom: 3px;
        color: var(--color-secondary);
    }

    .mktoFormRow {
        display: flex;
        width: 100% !important;
        flex-flow: column;
        @media(${queries.min_break_md}){
            flex-flow: row;
        }
    }

    input {
        height: 40px;
        border: 1px solid #AEAEAE;
        border-radius: 2px;
        width: 100% !important;
        font-family: var(--fontFamily-body);
        padding: 0 10px;
    }

    .mktoButton {
        display: inline;
        padding: 12px 30px;
        border-radius: 100px;
        font-weight: bold;
        font-size: var(--fontSize-1);
        min-width: 175px;
        display: inline-block;
        text-align: center;
        background-color: var(--color-primary);
        color: white;
        font-family: var(--font-body);
        border: none;
        transition: all .1s;

        &:hover {
            color: white;
            text-decoration: none;
            background-color: var(--color-primary-dark);
        }
        &:active{
            background-color: var(--color-primary-darker);
        }
    }

    // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
    @media(${queries.max_break_md}){
        #Phone, #PostalCode{
            max-width:200px;
        }
    }
    .mktoRadioList {
      max-width: 200px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important;
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        padding-left: 4px!important;
        font-size: var(--fontSize-0);
        font-weight: 300;
    }
`;

const Legal = styled.p`
    font-size: var(--fontSize-legal);
    width: 100%;
    align-self: flex-start;
    margin-top: 15px;
    @media(${queries.min_break_md}){
        width: 70%;
    }

`;


const ThankYouContainer = styled.div`
  background-color: #f6f6f6;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  font-family: var(--font-body);
  box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  min-height: 50vh;
  border-radius:4px;
`;
const ThankYouImage = styled.div`
  background-image: url('https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,fl_lossy,q_80/v1627674140/Grubhub%20Corporate%20Website/2021/Photography%20Elements/5-ContactUs/GeneralContactUs-Image-SuccessState.jpg');
  width: 100%;
  height: 100%;
  background-size:cover;
  background-position: center center;
  border-radius:4px 0 0 4px;
  @media(${queries.max_break_lg}){
    min-height:350px;
  }
`;
const ThankYouMessageContainer = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: center;
  text-align:center;
  padding: 50px;
  h2{
    font-weight:bold;
    font-size: var(--fontSize-5);
    margin:20px 0;
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-3);
    }
  }
  p{
    font-size: var(--fontSize-2);
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-1);
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: -30px;
  position: relative;
`;
